import PropTypes from 'prop-types'
import React from 'react'

function LogoMobile({ className }) {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='spring-initializr'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 454 145'
    >
      <g>
        <path
          className='st0'
          d='M153.4,62.6l-30.2-52.3c-2.6-4.5-9.1-8.3-14.3-8.3H48.5c-5.2,0-11.7,3.7-14.3,8.3L4,62.6
      		c-2.6,4.5-2.6,12,0,16.5l30.2,52.3c2.6,4.5,9.1,8.3,14.3,8.3h60.4c5.2,0,11.7-3.7,14.3-8.3l30.2-52.3
      		C156.1,74.5,156.1,67.1,153.4,62.6z'
        />
        <g>
          <circle className='st1' cx='36.7' cy='115.2' r='6.1' />
        </g>
        <g>
          <path
            className='st1'
            d='M139,97.4C121.3,121,83.5,113,59.3,114.2c0,0-4.3,0.2-8.6,0.9c0,0,1.6-0.7,3.7-1.4c17-5.9,25-7.1,35.4-12.4
      			c19.4-9.9,38.7-31.6,42.7-54.1c-7.4,21.6-29.9,40.3-50.3,47.8c-14,5.2-39.3,10.2-39.3,10.2l-1-0.5C24.5,96.4,24,59,55.3,47
      			C69,41.7,82.2,44.6,97,41.1c15.8-3.8,34.1-15.6,41.6-31.1C146.9,34.8,156.9,73.5,139,97.4z'
          />
          <path
            className='st0'
            d='M138.6,10c8.3,24.8,18.4,63.5,0.4,87.4c-11.1,14.8-30.1,17.2-48.7,17.2c-8.8,0-17.5-0.5-25.2-0.5
      			c-2,0-3.9,0-5.7,0.1c0,0-4.3,0.2-8.6,0.9c0,0,1.6-0.7,3.7-1.4c17-5.9,25-7.1,35.4-12.4c19.4-9.9,38.7-31.6,42.7-54.1
      			c-7.4,21.6-29.9,40.3-50.3,47.8c-14,5.2-39.3,10.2-39.3,10.2l0,0l-1-0.5C24.5,96.4,24,59,55.3,47C69,41.7,82.2,44.6,97,41.1
      			C112.8,37.4,131.1,25.5,138.6,10 M139.1,2.1l-3.2,6.6c-7.5,15.6-25.8,26.2-39.6,29.5C90.1,39.7,84,40,78.2,40.3
      			c-7.7,0.4-15.7,0.8-23.9,3.9C35,51.6,27.6,67.8,27.1,80.5c-0.5,12.1,4.7,22.6,13.2,26.9c0.5,0.3,1.6,0.9,2.5,0.9h1.3l0.2-0.2
      			c5.8-1.2,26.5-5.6,38.9-10.1c8.6-3.2,18.3-8.6,27.1-15.8c-6.5,6.9-14.1,12.7-21.8,16.6c-6.3,3.2-11.7,4.9-19.2,7.2
      			c-4.4,1.3-9.5,2.9-15.7,5c-2.2,0.7-3.8,1.5-3.9,1.5l-2.2,4.5l3.9,1.2c4.1-0.7,8.2-0.9,8.3-0.9c1.7-0.1,3.5-0.1,5.5-0.1
      			c3.7,0,7.6,0.1,11.7,0.3c4.4,0.1,8.9,0.3,13.5,0.3c18.5,0,39-2.2,51.1-18.4c14.1-18.8,14.2-48.3,0.1-90.2L139.1,2.1L139.1,2.1z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className='st0'
            d='M182.5,59.7c-0.9-0.5-1.5-1.5-1.5-2.8c0-1.8,1.5-3.3,3.3-3.3c0.7,0,1.3,0.2,1.8,0.5c3.5,2.3,7.1,3.5,10.3,3.5
      			c3.5,0,5.6-1.5,5.6-3.9v-0.2c0-2.8-3.8-3.8-8-5c-5.3-1.5-11.2-3.6-11.2-10.5v-0.2c0-6.8,5.6-10.9,12.7-10.9
      			c3.8,0,7.8,1.1,11.3,2.9c1.2,0.6,2,1.7,2,3.1c0,1.9-1.5,3.3-3.5,3.3c-0.7,0-1.1-0.2-1.6-0.4c-2.9-1.5-5.9-2.4-8.4-2.4
      			c-3.2,0-5,1.5-5,3.5v0.2c0,2.7,3.9,3.8,8.1,5.1c5.3,1.6,11.1,4.1,11.1,10.4v0.2c0,7.5-5.9,11.3-13.3,11.3
      			C191.5,64,186.6,62.5,182.5,59.7z'
          />
          <path
            className='st0'
            d='M212.5,31c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1v2.4c2.7-3.8,6.4-6.7,12.2-6.7c8.4,0,16.6,6.6,16.6,18.6v0.2
      			c0,11.9-8.2,18.6-16.6,18.6c-5.9,0-9.7-3-12.2-6.3v12.7c0,2.3-1.8,4.1-4.1,4.1c-2.2,0-4.1-1.8-4.1-4.1V31z M241.2,45.5v-0.2
      			c0-6.9-4.7-11.4-10.2-11.4c-5.6,0-10.4,4.7-10.4,11.4v0.2c0,6.8,4.8,11.4,10.4,11.4C236.5,57,241.2,52.6,241.2,45.5z'
          />
          <path
            className='st0'
            d='M252.6,31c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1v2c0.4-3,5.4-6.1,9-6.1c2.6,0,4.1,1.7,4.1,4.1
      			c0,2.1-1.5,3.6-3.3,3.9c-5.9,1-9.8,6.1-9.8,13.1v11.8c0,2.2-1.8,4.1-4.1,4.1c-2.2,0-4.1-1.8-4.1-4.1L252.6,31L252.6,31L252.6,31z'
          />
          <path
            className='st0'
            d='M277,31.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1v28.8c0,2.3-1.8,4.1-4.1,4.1c-2.2,0-4.1-1.8-4.1-4.1V31.1z'
          />
          <path
            className='st0'
            d='M289.4,31.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1v1.7c2.3-3.3,5.6-5.9,11.2-5.9
      			c8.1,0,12.7,5.4,12.7,13.7v19.3c0,2.3-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1V43.2c0-5.6-2.7-8.8-7.7-8.8c-4.7,0-8.1,3.3-8.1,8.9v16.7
      			c0,2.3-1.8,4.1-4.1,4.1c-2.2,0-4.1-1.8-4.1-4.1V31.1L289.4,31.1z'
          />
          <path
            className='st0'
            d='M357.5,26.9c-2.3,0-4.1,1.8-4.1,4.1v2.4c-2.7-3.8-6.4-6.7-12.2-6.7c-8.4,0-16.6,6.6-16.6,18.6v0.2
      			c0,11.9,8.2,18.6,16.6,18.6c5.9,0,9.7-3,12.2-6.2c-0.4,6.5-4.4,9.8-11.3,9.8c-4.1,0-7.7-1-11-2.8c-0.4-0.2-0.9-0.3-1.5-0.3
      			c-1.9,0-3.5,1.5-3.5,3.3c0,1.6,0.9,2.7,2.3,3.3c4.4,2.1,8.8,3.2,13.9,3.2c6.5,0,11.5-1.5,14.7-4.8c3-3,4.5-7.4,4.5-13.4V31
      			C361.6,28.7,359.7,26.9,357.5,26.9z M343.1,56.9c-5.6,0-10.2-4.4-10.2-11.5v-0.2c0-6.9,4.7-11.4,10.2-11.4
      			c5.6,0,10.4,4.7,10.4,11.4v0.2C353.6,52.3,348.7,56.9,343.1,56.9z'
          />
          <path
            className='st0'
            d='M285.3,17.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1C283.3,12.9,285.3,14.8,285.3,17.1
      			z'
          />
        </g>
        <g>
          <path
            className='st0'
            d='M371,35.5c-2.3,0-4.2-1.9-4.2-4.2c0-2.4,1.9-4.2,4.2-4.2c2.4,0,4.2,1.9,4.2,4.2S373.3,35.5,371,35.5z
      			 M371,27.7c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6C374.5,29.2,373,27.7,371,27.7z M372.1,33.6l-1.2-1.9H370
      			v1.9h-0.7v-4.8h2c0.8,0,1.6,0.6,1.6,1.5c0,1.1-1,1.5-1.2,1.5l1.3,1.9H372.1L372.1,33.6z M371.3,29.5H370v1.7h1.3
      			c0.4,0,0.8-0.3,0.8-0.8C372.2,29.8,371.7,29.5,371.3,29.5z'
          />
        </g>
      </g>
      <g>
        <path
          fill='currentColor'
          d='M181,86.4c0-2.7,2.2-4.9,5-4.9c2.7,0,4.9,2.3,4.9,4.9c0,2.7-2.2,5-4.9,5C183.2,91.5,181,89.1,181,86.4z M181.6,97h8.7v36.8
      		h-8.7V97z'
        />
        <path
          fill='currentColor'
          d='M233.7,110.4v23.4h-8.4v-21.7c0-5-3.2-8.5-7.8-8.5c-4.5,0-8.4,3.1-8.9,7v23.3h-8.7V97h8.7v4.9c2.3-3.4,6.5-5.8,11.4-5.8
      		C228,96.1,233.7,102.1,233.7,110.4z'
        />
        <path
          fill='currentColor'
          d='M242.5,86.4c0-2.7,2.2-4.9,5-4.9c2.7,0,4.9,2.3,4.9,4.9c0,2.7-2.2,5-4.9,5C244.7,91.5,242.5,89.1,242.5,86.4z M243.1,97
      		h8.7v36.8h-8.7V97z'
        />
        <path
          fill='currentColor'
          d='M282.2,131.8c-2.1,1.8-5.1,2.9-7.8,2.9c-6.1,0-10.4-4.4-10.4-10.6v-19.9h-5.3V97h5.3V86.9h8.5V97h8.4v7.2h-8.4V123
      		c0,2.5,1.6,4.3,3.6,4.3c1.5,0,2.8-0.5,3.6-1.3L282.2,131.8z'
        />
        <path
          fill='currentColor'
          d='M288.5,86.4c0-2.7,2.2-4.9,5-4.9c2.7,0,4.9,2.3,4.9,4.9c0,2.7-2.2,5-4.9,5C290.7,91.5,288.5,89.1,288.5,86.4z M289,97h8.7
      		v36.8H289V97z'
        />
        <path
          fill='currentColor'
          d='M339.2,111.7v22.2h-8.5v-4.4c-2.7,3.4-7.3,5.3-11.6,5.3c-7.8,0-13.7-4.6-13.7-11.7c0-7.3,6.8-12.2,15-12.2
      		c3.3,0,7,0.7,10.4,2v-1c0-4.3-2.4-8.4-9-8.4c-3.5,0-6.8,1.2-10,2.8l-3-6.1c5.2-2.5,10.1-3.9,14.7-3.9
      		C333.2,96.1,339.2,102.4,339.2,111.7z M330.7,122.3v-4.1c-2.7-0.9-5.8-1.5-9-1.5c-4.4,0-7.8,2.4-7.8,5.9c0,3.5,3.1,5.7,7.2,5.7
      		C325.2,128.3,329.7,126.2,330.7,122.3z'
        />
        <path fill='currentColor' d='M348.6,84h8.7v49.8h-8.7V84z' />
        <path
          fill='currentColor'
          d='M366.3,86.4c0-2.7,2.2-4.9,5-4.9c2.7,0,4.9,2.3,4.9,4.9c0,2.7-2.2,5-4.9,5C368.5,91.5,366.3,89.1,366.3,86.4z M366.9,97
      		h8.7v36.8h-8.7V97z'
        />
        <path
          fill='currentColor'
          d='M383.7,127.5l19.6-22.9h-19.1V97h30.6l-0.1,6.4l-19.6,22.9h19.8v7.6h-31.1L383.7,127.5L383.7,127.5z'
        />
        <path
          fill='currentColor'
          d='M444.8,96.1v7.4c-7.9,0-13.2,4.7-13.2,11.7v18.6h-8.7V97h8.7v7.1C434,99.2,438.8,96.1,444.8,96.1z'
        />
      </g>
    </svg>
  )
}

LogoMobile.defaultProps = {
  className: '',
}

LogoMobile.propTypes = {
  className: PropTypes.string,
}

export default LogoMobile
