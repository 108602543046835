import React from 'react'

import HeaderMobile from './HeaderMobile'
import Logo from './Logo'

function Header() {
  return (
    <header id='header'>
      <div className='not-mobile'>
        <h1 className='logo'>
          <a href='/'>
            <span className='logo-content' tabIndex='-1'>
              <Logo />
            </span>
          </a>
        </h1>
        	<div className='banner'>
			 <a href='https://springdoc.cn' target="_blank">优质的 spring/boot/security/data/cloud 中文文档尽在 => springdoc.cn</a>
			</div>
      </div>
      <HeaderMobile />
    </header>
  )
}

export default Header
